import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function Privacy({
  show,
  onHide,
  isChecked,
  onCheckboxChange,
}: any) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    onCheckboxChange(isChecked);
  };

  return (
    <Modal className="baModal modal-kyc" show={show} onHide={onHide} animation={false} size="lg">
      {/* <Modal.Header
        className="header-popup"
        closeButton 
        placeholder={null}
      ></Modal.Header> */}
      <div className="header-popup modal-header">
        <button onClick={onHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
      </div>
      <Modal.Title>
        <h6 className="assessques h5 thick-heading">
          Privacy Policy
        </h6>
      </Modal.Title>
      <Modal.Body>
        <div className="text-justify">
          <p className="assessques thick-heading mt-4">
            <strong>RPM by OtisHealth &ndash; Privacy Policy</strong>
          </p>
          <p className="assessques thick-heading mt-4">
            Last Updated/Effective Date: July 16, 2024
          </p>
          <p className="graphText thin-heading mt-3">
            OtisHealth, Inc. is committed to preserving the privacy of your personal and medical information.  Our Privacy Policy explains what information we gather, use and share when you use our website otishealth.net and the RPM by OtisHealth  mobile application (collectively, "RPM" or the "Platform").
          </p>

          <p className="assessques thick-heading mt-3">
            Acceptance of Privacy Policy
          </p>

          <p className="graphText thin-heading mt-3 pb-10">
            By using RPM, you agree to the terms of this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not use RPM.{" "}
            <span className="graphText thick-heading">
              {" "}
              We may update our Privacy Policy from time to time, and your continued use of the Platform means that you agree to those changes.{" "}
            </span>
          </p>

          <p className="assessques thick-heading mt-3">
            Information We Collect and How We Collect It
          </p>

          <p className="graphText thin-heading mt-3">
            As part of this Platform, we collect the below certain information from patients, caregivers, and healthcare providers as noted below.
          </p>

          <p className="assessques thick-heading mt-3">Personal Information</p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              <i>For Healthcare Providers:</i>{" "}
            </span>
            We collect your name, mailing address, e-mail address, mobile phone
            number, your individual National Provider Identifier (NPI), your
            practice specialty, the name of your affiliated medical
            practice/organization, and your medical practice/organization NPI.
            We collect this Personal Information when you register as a
            Healthcare Provider on RPM.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              <i>For Patients:</i>{" "}
            </span>{" "}
            We collect your name, e-mail address, phone number, date of birth, and your gender.  We collect this Personal Information when you are enrolled as an RPM patient or caregiver.
          </p>

          <p className="assessques thick-heading mt-3">Health Information</p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              <i>For Patients:</i>{" "}
            </span>
            We collect your health information including your diagnoses, medical devices used to take vitals readings, medical device measurements, and information identifying your healthcare providers.
          </p>

          <p className="graphText mt-3">
            We collect your health information in the following ways :
          </p>

          <ol className="graphText ol" type="i">
            <li>when you provide permission for the Platform to access data and applications on your mobile device,</li>
            <li>when you provide permission for the Platform to receive data from your online account that stores your health monitoring data, </li>
            <li>when you connect and sync health monitoring devices, we collect the information transmitted from the device to the Platform.</li>
          </ol>

          <p className="graphText thin-heading mt-3 pb-10">
            <span className="graphText thick-heading">
              <i>For Healthcare Providers:</i>{" "}
            </span>
            The information collected and processed regarding your patients will
            be governed by both this Privacy Policy and a Business Associate
            Agreement with OtisHealth.
          </p>

          <p className="assessques thick-heading mt-3">
            Information Automatically Collected
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">Account Activity. </span>
            We may collect data about how you use your online account and the
            Platform when you are logged into your account. We may use IP
            addresses to conduct website analyses and performance reviews and to
            administer our website.
          </p>

          <p className="assessques thick-heading mt-3">
            Cookies & Other Tracking Technologies.
          </p>
          <p className="graphText thin-heading">
            A cookie is a small text file that our Platform saves onto your
            computer or device when you use the Platform that provides us
            certain information about your activities. Other tracking
            technologies like web beacons/pixel tags are small graphics on a
            webpage that monitor your activity when viewing a webpage.
          </p>

          <p className="graphText mt-3">
            These tools (collectively, “Cookies”)
            <ul>
              <li>
                allow the Platform: to remember your actions and preferences and
                recognize you or your browser, along with some information you
                provided.{" "}
              </li>
              <li>
                allow us to provide you with a more personalized experience.{" "}
              </li>
            </ul>
          </p>

          <p className="graphText mt-3">
            Cookies may collect information such as your device’s IP address,
            location and pages visited. Most browsers automatically accept
            cookies, and you can manually delete Cookies through your browser
            settings. Also you can disable this function by changing your
            browser settings, but disabling cookies may impact your use and
            enjoyment of the Platform. Not all features or functions of the
            Platform may work properly if you disable Cookies. You cannot
            disable all Cookies, such as Cookies that are essential to the
            functioning of the Platform.
          </p>

          <p className="assessques thick-heading mt-3">
            CALIFORNIA'S "DO-NOT-TRACK" REQUIREMENT. WE CURRENTLY DO NOT HONOR
            “DO NOT TRACK” REQUESTS.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">Web Analytics. </span>We
            use 3rd party web analytics tools and services to collect and
            process information about your use of OtisHealth. These tools and
            services set cookies on your browser or device, and then your web
            browser will automatically send information to us. We use this
            information to better understand and measure how users interact with
            the Platform.
          </p>

          <p className="graphText thin-heading mt-3 pb-10">
            <span className="graphText thick-heading">
              Online Behavioral Advertising.{" "}
            </span>
            We do not use third parties and/or service providers to provide
            interest-based advertising services.
          </p>

          <p className="assessques thick-heading mt-3">
            How We Use Your Information.
          </p>

          <p className="graphText mt-3">
            We collect and use your identifiable data and information for the
            following purposes:
            <ul>
              <li>To provide the primary service of the Platform.</li>
              <li>
                To verify your identity before we begin collecting your health
                information and records. For example, we use your phone number
                to send you text messages for multi-factor authentication and
                other security measures. Additional verification information may
                be required by healthcare providers before they release your
                electronic health records to you.{" "}
              </li>
              <li>
                To respond to your questions, comments, or complaints. We may
                also use personal information as requested or consented to by
                you.
              </li>
              <li>
                To locate your health records and assist healthcare providers
                with accurately verifying and sending us your correct health
                records for your account.
              </li>
              <li>
                To support company operations like quality control, fraud
                detection and developing new products and services.{" "}
              </li>
              <li>To notify you of new features, services or products.</li>
              <li>To conduct research, education and training. </li>
              <li>
                To fulfill internal business purposes such as data analysis,
                audits, identifying usage trends, and improving our services.
              </li>
              <li>
                To comply with any applicable legal or regulatory obligations.
              </li>
            </ul>
          </p>

          <p className="assessques mt-4 thick-heading">Storage of Data</p>

          <p className="graphText mt-3 pb-10">
            We store your data on your device. We also store your data outside
            the device at our company or through third party secure cloud
            storage services.
          </p>

          <p className="assessques mt-4 thick-heading">Encryption of Data</p>

          <p className="graphText mt-3 pb-10">
            We automatically encrypt your data in the device or app. We
            automatically encrypt your data when stored on our company servers
            or with outside cloud computing service providers. We automatically
            encrypt your data while it is transmitted.
          </p>

          <p className="assessques mt-4 thick-heading">
            Retention of Information
          </p>

          <p className="graphText mt-3 pb-10">
            Unless a longer retention period is permitted or required by law,
            when your account is deactivated/terminated by you or OtisHealth,
            your data will be deleted after 90 days so it is important that you
            download the records inputted by you (or in the case of healthcare
            providers, the records of your patients) for future use and
            retention.
          </p>

          <p className="assessques mt-4 thick-heading">
            Disclosure and Sharing of Your Information
          </p>

          <p className="graphText mt-3">
            We do not sell, rent or lease any of your identifiable or
            non-identifiable (data in which personal identifiers have been
            removed) personal information to any third party. We do not share
            your identifiable data. We may share personal information and data
            only after removing identifiers (note that remaining data may not be
            anonymous) for the purposes of providing the primary service of the
            app or technology, supporting company operations and developing and
            improving new and current products and services. Such
            identifier-removed information may be also be shared with the
            following parties, in compliance with applicable laws:
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Employees and Affiliates.{" "}
            </span>
            We may share personal information with our employees and affiliates
            who have a need to know the information for our business purposes.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Third-Parties and/or Service Providers.{" "}
            </span>
            We may share personal information with third party contractors and/or service providers that provide services for us. For example, we may share personal information with cloud storage providers, e-mail marketing vendors,
            security vendors, and data analytics vendors.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Clinical Trial Organizations/Research Partners.{" "}
            </span>
            We may provide your de-identifiable personal and health information to a clinical trial
            organization or research partner for the purpose of finding potential study candidates.
            If OtisHealth notifies you that you qualify for a specific clinical research opportunity, and you request to participate, with your permission, we will share your personal information, including necessary health information and records, with the sponsoring clinical trial organization or research partner to match you with a specific clinical trial or research study.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Government Officials/Law Enforcement.{" "}
            </span>
            We will cooperate with law enforcement and other governmental agencies, and may disclose personal information:  (i) if we believe in good faith we are legally required to disclose that personal information, (ii) if we are advised to disclose personal information by our legal counsel, or (iii) when necessary to identify, contact or bring a legal action against someone who may cause or be causing harm to, or interfering with the legal rights of, OtisHealth or any
            other party.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Professional Advisors.{" "}
            </span>
            We may share personal information with our professional advisors, such as our attorneys, accountants,
            financial advisors and business advisors, in their capacity as advisors to OtisHealth.
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Change in Ownership.  {" "}
            </span>
            In the event OtisHealth is the subject of a change of control or in the event the Platform changes ownership, in whole or in part, or in the event of a bankruptcy, receivership or a similar transaction, we may provide personal information to the subsequent
            owner(s).
          </p>

          <p className="graphText thin-heading mt-3">
            <span className="graphText thick-heading">
              Other.{" "}
            </span>
            We may share personal information with third parties when explicitly requested by or consented to by you, or for the purposes for which you disclosed the personal information to us as indicated at the time and point of the disclosure
            (or as was obvious at the time and point of disclosure).
          </p>

          <p className="graphText thin-heading mt-3 pb-10">
            <span className="graphText thick-heading">
              Social Media.{" "}
            </span>
            This Platform does NOT allow you to share the collected data with your social media accounts, like Facebook.
          </p>

          <p className="assessques mt-4 thick-heading">
            Use and Disclosure of Non-Personal Information.
          </p>

          <p className="graphText thin-heading mt-3">
            OtisHealth may collect, use, share, transfer and otherwise process de-identified and aggregated information that it receives or creates for any purposes in its sole discretion, in compliance with applicable laws. OtisHealth is the sole and exclusive owner of such de-identified and aggregated information, including if OtisHealth de-identifies personal
            information so that it no longer considered personal information under applicable laws.
          </p>

          <p className="graphText thin-heading mt-3 pb-10">
            <span className="graphText thick-heading">
              Children's Information.{" "}
            </span>
            Our Platform is not directed at children under 18 years of age, nor is it intended to be used by users under 18 years of age. We do not knowingly collect, use, or share personal information from children under 18. If a parent or legal guardian learns that their child provided us with personal information without his or her consent, please contact us and we will make commercially reasonable
            attempts to delete such personal information.
          </p>

          <p className="assessques mt-4 thick-heading">
            Data Security.
          </p>

          <p className="graphText mt-3">We use commercially reasonable technical and organizational measures to help
            secure personal information against loss, misuse, and alteration appropriate to the type of
            personal information processed. If a breach of your personal information occurs, Members affected
            by a data breach will be notified via secure message center and an email.  Passwords for affected
            accounted will be invalidated and will require new passwords to be established.  Any data deleted or
            corrupted by a bad actor (i.e. hacker or malicious software) will, if possible, recovered from secure
            back-up servers.  Public disclosure of any data breach will be made as required by FTC Health Data
            Breach regulations.
          </p>
          <p className="graphText mt-3 pb-10">YOU UNDERSTAND THAT NO DATA TRANSMISSION OVER THE INTERNET OR DEVICE CAN BE GUARANTEED TO BE 100% SECURE. WHILE WE STRIVE TO PROTECT PERSONAL INFORMATION, WE DO NOT GUARANTEE THE SECURITY OF PERSONAL INFORMATION AND YOU PROVIDE PERSONAL INFORMATION AT YOUR OWN RISK.</p>


          <p className="assessques mt-4 thick-heading">
            Access from Outside the United States
          </p>
          <p className="graphText mt-4 pb-10">
            If you access the Platform from outside the United States, please be aware that personal information may be transferred to, stored in, and processed in the United States. Certain governmental authorities may not consider the level of protection of personal information in the United States to be equivalent to that required by the in other jurisdictions.
          </p>

          <p className="assessques mt-4 thick-heading">
            Third-Party Websites
          </p>
          <p className="graphText mt-4 pb-10">
            The Platform may link to, or be linked to, websites not controlled by us. We are not responsible for third-parties’ privacy policies or practices. This Policy does not apply to any third-party websites or to any data that you provide to third parties. You should read the privacy policy for each website that you visit.
          </p>

          <p className="assessques mt-4 thick-heading">
            Access, Update or Delete Your Information
          </p>
          <p className="graphText mt-4 pb-10">
            Consistent with the laws of your state, this platform allows you to access, edit, share or delete the data we have about you.  Data sharing may only occur using the Platform and only to other users and individuals to whom you have authorized access.  To access or update your personal information as it exists in our records, please visit your account or
            contact us using the information below.
          </p>

          <p className="assessques mt-4 thick-heading">
            Content Provided is NOT Medical Advice and is For Informational Purposes Only
          </p>
          <p className="graphText mt-4">
            All content found on the Platform including text, images, audio, or other formats were created for informational purposes only.  The content is not intended to be medical advice or a diagnosis. The content is not a substitute for professional medical advice, diagnosis, or treatment.  Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.  Never disregard professional medical advice or delay in seeking it because of something you have read on this platform. Links to content not created by OtisHealth are taken at your own risk.  OtisHealth is not responsible for the claims of external websites and education
            companies.
          </p>

          <p className="graphText thick-heading ml-5 pb-10">
            If you think you may have a medical emergency, call your doctor, go to the emergency department, or call 911 immediately.  OtisHealth does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the website or app.  Reliance on any information provided by OtisHealth, is solely at your own risk.
          </p>

          <p className="assessques mt-4 thick-heading">
            Use By Non-Residents
          </p>
          <p className="graphText mt-4 pb-10">
            This Privacy Policy is intended to meet the laws and regulations of the United States, which may not necessarily be consistent with the laws and regulations of your home country.  Any information that you provide OtisHealth will be treated in accordance with this Privacy Policy, the Terms of Use, and U.S. laws. The Platform is not intended for use outside the United States.
          </p>
          <p className="assessques mt-4 thick-heading">
            Contact Us
          </p>
          <p className="graphText mt-4">
            If you have questions or concerns regarding this Policy, contact us at:
          </p>
          <p className="graphText mt-4">
            OtisHealth, Inc.
            <p>21701 Stevens Creek Blvd. #1442, Cupertino, CA 95015 USA</p>
          </p>

          <p className="assessques mt-4 thick-heading">
            Privacy Policy: <a href="https://otishealth.net/rpm_privacy" target='_blank'>https://otishealth.net/rpm_privacy</a><br />

            <span className="assessques mt-4 thick-heading">
              Contact Form:  <a href="https://otishealth.net/contact/" target='_blank'>https://otishealth.net/contact/</a>
            </span><br />
            <span className="assessques mt-4 thick-heading">
              Email:  <a href="privacy@otishealth.net" target='_blank'>privacy@otishealth.net </a>
            </span><br />

            <span className="assessques mt-4 thick-heading">
              Phone: <a href="tel: 4083206330">(408) 320-6330</a>
            </span>
          </p>


          <div className="d-flex align-items-center">
            <div className="form-check check-box" style={{ height: 35 }}>
              <input
                checked={checked}
                // onClick={onClick}
                onChange={handleCheckboxChange}
                className="form-check form-check-input"
                type="checkbox"
                id="gridCheck1"
              />
            </div>
            <p className="ml-3 thin-heading graphText dashColor">
              I agree to the <span className="textColor">Privacy Policy</span>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
