import React, { useRef, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot, ReferenceLine
} from 'recharts';
import './styles.css';
import { getDDDMMDD, getTime12hrsData, convertDate } from '../../utils/dateHelper';
import moment from "moment"

const BloodPressureGraph = ({ data, usePdf,  isMonitoringAssigned  }) => {
  const bloodPressureGraphRef = useRef(null);

  const calculateBarchartWidth = (numberOfItem = 7) => { if (numberOfItem > 6) return numberOfItem * 75; return '100%'; };

  const CustomDot = ({ cx, cy, fill, payload, dataKey }) => {
    let dotColor = fill;

    if (dataKey === 'diastolic') {
      dotColor = payload.isAbnormalDiastolic ? 'red' : '#E2B71F';
    } else if (dataKey === 'systolic') {
      dotColor = payload.isAbnormalSystolic ? 'red' : '#89C9F8';
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={dotColor}
        stroke="none"
        style={{ pointerEvents: 'none' }}
      />
    );
  };

  useEffect(() => {
    if (bloodPressureGraphRef.current) {
      bloodPressureGraphRef.current.scrollLeft = bloodPressureGraphRef.current.scrollWidth;
    }
  }, [data]);

  const formattedData = (data)?.map(entry => ({
    date: getDDDMMDD(entry.date),
    systolic: entry.systolic,
    diastolic: entry.diastolic,
    isAbnormalDiastolic: entry.isAbnormalDiastolic,
    isAbnormalSystolic: entry.isAbnormalSystolic,
  })) || [];

  const dummyTableData = (data)?.map(entry => ({
    dateTime: `${moment(entry?.date).format('MM/DD/YYYY hh:mm A')}`,
    device: entry.device,
    range: `${entry.range} ${entry.unit}`
  })) || [];


  if (!isMonitoringAssigned) {
    return null; 
}


  return (
    <div className="graph-container">
      {!usePdf && <h2 className="graphHeading">Blood Pressure</h2>}
      {(data && data.length) ? (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '65px' }}>
          <ResponsiveContainer width={'100%'} height={275}>
            <LineChart data={formattedData?.reverse()}>
              <YAxis
                dy={20}
                type="number"
                dataKey='systolic'
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                tick={{ fill: '#000', fontSize: 12 }}
                padding={{ right: 20 }}
                tickLine={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }} ref={bloodPressureGraphRef}>
          <ResponsiveContainer width={calculateBarchartWidth(formattedData.length)} height={300}>
            <LineChart data={formattedData}>
              <XAxis
                dataKey="date"
                angle={0}
                dx={-30}
                minTickGap={-200}
                textAnchor="start"
                tick={{ fill: '#000', fontSize: 12 }}
                tickLine={false}
                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                padding={{ right: 20 }}
              />
              <Tooltip />
              <Legend
                verticalAlign="top"
                align="right"
                iconType="circle"
                iconSize={26}
                wrapperStyle={{ paddingBottom: 20, paddingLeft: 20 }}
                formatter={(value, entry) => (
                  <span style={{ color: '#000', fontSize: 14 }}>
                    {value}
                  </span>
                )}
              />
              <Line
                type="monotone"
                dataKey="systolic"
                stroke="#89C9F8"
                name="Systolic"
                strokeWidth={3}
                dot={(props) => <CustomDot {...props} dataKey="systolic" />}
              />
              <Line
                type="monotone"
                dataKey="diastolic"
                stroke="#E2B71F"
                name="Diastolic"
                strokeWidth={3}
                dot={(props) => <CustomDot {...props} dataKey="diastolic" />}
              />
              <ReferenceLine y={140} stroke="black" strokeDasharray="3 3" />
              <ReferenceLine y={70} stroke="black" strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>) : (
        <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>
          No Chart Data Found
        </p>
      )}


      {!usePdf && <div className='graphtable-container'>
        <table className='graphdata-table'>
          <thead className='graphTableHead'>
            <tr className='graphTableborder'>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Date & Time</th>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Device</th>
              <th style={{ fontSize: 18, fontWeight: '700', color: '#000000' }}>Range</th>
            </tr>
          </thead>
          <tbody>
            {dummyTableData.map((entry, index) => (
              <tr key={index}>
                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.dateTime}</td>
                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.device}</td>
                <td style={{ fontSize: 16, fontWeight: '400', color: '#000000' }}>{entry.range}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {dummyTableData.length == 0 && <p>
          No Data Found
        </p>}
      </div>}
    </div>
  );
};

export default BloodPressureGraph;
