import React, { useEffect, useState } from "react";
import "./styles.css";
import AppServices from "../../../services/appServices";
import { convertDate } from "../../utils/dateHelper";
import { APIEndpoint } from "../../../config/apiendpoint";
import { Tooltip } from 'react-tooltip';
import { TailSpin } from 'react-loader-spinner';
function DeviceTraining({ patientData, addMonitoring, setGetTraining }) {
  const _appServices = new AppServices();
  const [deviceTrainingData, setDeviceTrainingData] = useState([]);
  const [loading,setLoading] = useState(true)
  useEffect(() => {
    getDeviceTrainingDatas();
  }, [setGetTraining]);

  const getDeviceTrainingDatas = async () => {
    try {
      const resp = await _appServices.getData(
        `${APIEndpoint.deviceTraining}?patientUid=${patientData?.uuid}`
      );
      if (resp?.status === 200 && resp?.success == true) {
        let data = [];
        if (resp.data && resp.data.length) {
          resp.data.map((item) => {
            data.push({
              measure: item.measurementName,
              device: item.deviceName,
              staff: `${item?.staffDetails?.firstName} ${item?.staffDetails?.lastName}`,
              dateComplete: convertDate(item.dateOfTraining),
              item: item,
            });
          });
          setDeviceTrainingData(data);
          
        }
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  return (
    <div className="graph-container">
      <div className="header-container">
        <h2 className="graphHeading ml-5 mb-4">Device Training</h2>
        <button
          className="float-right training-button ml-5 mb-4"
          onClick={addMonitoring}
        >
          {" "}
          + Add Training
        </button>
      </div>
      <div className="bordertable-container ml-5">
        <table className="borderdata-table">
          <thead>
            <tr>
              <th>Measure</th>
              <th>Device</th>
              <th>Staff Name</th>
              <th>Date Complete</th>
            </tr>
          </thead>
          <tbody>
            {deviceTrainingData.length
              ? deviceTrainingData.map((entry, index) => (
                <tr key={index}>
                  <td className="text-truncate"><span data-tooltip-id="tooltip" data-tooltip-content={entry?.measure?.length > 13 ? entry?.measure : ''}>{entry?.measure}</span> </td>
                  <td className="text-truncate"><span data-tooltip-id="tooltip" data-tooltip-content={entry?.device?.length > 13 ? entry?.device : ''}>{entry?.device}</span></td>
                  <td className="text-truncate"><span data-tooltip-id="tooltip" data-tooltip-content={entry?.staff?.length > 13 ? entry?.staff : ''}>{entry?.staff}</span></td>
                  <td>{entry?.dateComplete}</td>
                </tr>


              ))
              : ""}
          </tbody>
        </table>
        {
          loading == true && <div className="d-flex align-items-center justify-content-center pt-5">
          <TailSpin
              visible={true}
              height="40"
              width="40"
              color="#03A84E"
              ariaLabel="tail-spin-loading"
              radius="1"
          />
      </div>
        }

        <Tooltip
          id="tooltip"
          place="bottom"
          type="light"
          effect="solid"
          className="custom-tooltip"
        />

        {(deviceTrainingData.length === 0 && loading == false) && (
          <div className="d-flex align-items-center justify-content-center pt-2">
            <p className="graphText thin-heading">No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeviceTraining;
