import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';

export const top_header_color = 'linear-gradient(90deg, #06AE7A 0%, #00A10B 100%)'
export const bottom_header_color = '#F2F6F4'
export const primary = '#03A84E'
export const secondary = '#3889B1'
export const white = '#fff';
export const gray = '#9AA5B5';

export const selectStyle = {
  control: (baseStyles: any, state: { isFocused: any; }) => ({
    ...baseStyles,
    border: '1.5px solid #9AA5B5',
    borderRadius: '12px',
    padding: "0px 15px 0px 30px",
    fontSize: '16px',
    height: '50px',
  }),
  indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
  })
}
export const selectStyle60 = {
  control: (baseStyles: any, state: { isFocused: any; }) => ({
    ...baseStyles,
    border: '1.5px solid #9AA5B5',
    borderRadius: '12px',
    padding: "0px 15px 0px 30px",
    fontSize: '16px',
    height: '60px',
  }),
  indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
  })
}
export const selectStyleMobile = {
  control: (baseStyles: any, state: { isFocused: any; }) => ({
    ...baseStyles,
    border: '1.5px solid #9AA5B5',
    borderRadius: '12px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "0px 15px",
    fontSize: '16px',
    height: '60px',
    borderRight: "none",
  }),
  indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
  })
}

// export const selectStyle = {
//   control: (baseStyles: any, state: { isFocused: any; }) => ({
//     ...baseStyles,
//     border: '1.5px solid #9AA5B5',
//     borderRadius: '12px',
//     padding: "0px 15px 0px 30px",
//     fontSize: '16px',
//     height: '60px',
//   }),
//   menu:(provided:any) =>({
//     ...provided,
//     "backgroundColor": colorTheme.pAlertBackground,
//     "color": secondary,
//     fontSize: '16px',
//   }),
//     indicatorSeparator: (provided: any) => ({
//       ...provided,
//       display: 'none',
//   }),
//   option: (provided:any) => ({
//     ...provided,
//     "backgroundColor": colorTheme.pAlertBackground,
//     "color": secondary,
//     "padding": "10px",
//     "border-top": `1px solid #A4AEBC`
//   }),
//   // singleValue: (provided:any) => ({
//   //   ...provided,
//   //   "padding": "200px",
//   //   backgroundColor:primary
//   // }),
// }

export const selectStyleSearch = {
  control: (baseStyles: any, state: { isFocused: any; }) => ({
    ...baseStyles,
    border: '1.5px solid #9AA5B5',
    borderRadius: '12px',
    padding: "0px 15px 0px 30px",
    fontSize: '16px',
    height: '60px',
    backgroundColor: 'white',
    color: '#00A10B'
    }),
  dropdownIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
  }),
  placeholder: (provided:any) => ({
    ...provided,
    color: '#00A10B',
  }),
  indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: white,
    borderRadius: '12px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    height: 'auto',
    position:'absolute',
    "z-index": "2",
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: state.isFocused ? 'black' : 'black',
    backgroundColor: state.isFocused ? '#eaedf3' : 'white',
    padding: '10px',
    borderRadius: '10px',
  }),
}

export const selectStyleSearchWhite = {
  control: (baseStyles: any, state: { isFocused: any; }) => ({
    ...baseStyles,
    border: '1.5px solid #9AA5B5',
    borderRadius: '12px',
    padding: "0px 15px 0px 30px",
    fontSize: '16px',
    height: '60px',
    }),
  dropdownIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
  }),
  indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: white,
    borderRadius: '12px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    height: 'auto',
    position:'absolute',
    "z-index": "2",
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: state.isFocused ? 'black' : 'black',
    backgroundColor: state.isFocused ? '#eaedf3' : 'white',
    padding: '10px',
    borderRadius: '10px',
  }),
}

export const colorTheme = {
  mainColor: '#0a3d5e',
  secondaryColor: '#0a3d5e',
  semanticGreen: '#84b999',
  semanticYellow: '#F0AD4E',
  semanticRed: '#CB2E25',
  semanticBlue: '#56C0E0',
  textColor: '#444445',
  dimGrey: '#dddddd',
  darkBlue: '#010743',
  pAlertBackground: '#F5F6F8',
  patientTableBackground: '#CCD2DA',
};

  
export const top_header_button = {
  "background": white,
  "color": secondary,
  "border":"3px solid " + secondary,
  "borderRadius": "25px",
  "width": 'auto', 
  "padding": '13px 15px',
  "maxWidth" : '250px', 
}
export const white_button = {
  background: '#FFF',
  color: secondary,
  border:"2px solid " + secondary,
  borderRadius: "30px",
  width: 'auto', 
  padding: '16px 32px 16px 32px'
}

export const dasboard_btn = {
  "backgroundColor" : top_header_color,
  "color": "#fff",
  "borderRadius": "16px",
  "padding": '6px 20px 6px 10px',
  "minWidth": '153px', 
  "height": 'auto',
  "border": '1.5px'
}

export const btn_primary = {
  "backgroundColor" : primary,
  "color": "#fff",
  "borderRadius": "30px",
  "width": 'auto', 
  "height": 'auto',
  "padding": '16px 32px', 
  "fontSize":'16px',
  "border" : '1px solid' + secondary,
}

export const btn_secondary = {
  "backgroundColor" : '#FFFFFF',
  "color": secondary,
  "border": "2px solid " +secondary,
  "borderRadius": "30px",
  "width": 'auto', 
  "height": 'auto',
  "padding": '16px 32px',
}

export const white_font_button = {
  "backgroundColor" : primary,
  "color": colorTheme.pAlertBackground,
  "border": "2px solid " +secondary,
  "borderRadius": "30px",
  "width": 'auto', 
  "height": 'auto',
  "padding": '16px 32px 16px 32px',
}

export const assess_table = {
  "height": "50px",
  "marginTop": "50px",
  "width": "100%",
  "backgroundColor": secondary,
  "color": colorTheme.pAlertBackground,
  "padding": '16px 32px 16px 32px',
}

export const horizontal_line = {
  "marginBottom": "10px",
  "marginTop": "30px",
  "padding": "0px",
  "borderBottom":"1px solid"
}

export const assess_box = {
  "padding": '16px 32px 16px 32px',
  "height": "145px",
  "marginTop":"36px",
  "width": "auto",
  "backgroundColor": colorTheme.pAlertBackground,
}
export const assess_question = {
  "marginBottom":"50px",
  "padding": '32px 32px 16px 32px',
  "height": "435px",
  "marginTop":"36px",
  "width": "auto",
  "backgroundColor": colorTheme.pAlertBackground,
}
export const assess_quesEdit = {
  // "padding": '32px 32px 16px 32px',
  "margin": "15px 0px 0px 0px",
  "height": "288px",
  "width": "auto",
  "backgroundColor": colorTheme.pAlertBackground,
}



interface Theme {
  background: string;
  text: string;
}

const lightTheme: Theme = {
  background: white,
  text: '#333',
};

const darkTheme: Theme = {
  background: '#333',
  text: white,
};

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  body {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
  }
`;

const ThemeContext = createContext<{
  theme: Theme;
  toggleTheme: () => void;
}>({
  theme: lightTheme,
  toggleTheme: () => {},
});

const ThemeProvider: React.FC<{ theme: Theme; children: React.ReactNode }> = ({ children, theme }) => {
  const [currentTheme, setTheme] = useState(theme || lightTheme);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === lightTheme ? darkTheme : lightTheme));
  };

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
      <StyledThemeProvider theme={currentTheme}>
        <GlobalStyle theme={currentTheme} />
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};
export { ThemeProvider, useTheme, lightTheme };
