

export const currentDate = (dateString = new Date().toISOString()) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

export const timeFormat = (date) => {
  return new Date(date).toLocaleTimeString(
    "en-US",
    { hour: "numeric", minute: "numeric", hour12: true }
  )
};

export const convertDateFormat = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export const convertTableDateFormat = (dateTimeString) => {
  const date = new Date(dateTimeString);
  
  const month = date.getMonth() + 1; // 0-indexed month
  const day = date.getDate();
  const year = date.getFullYear();

  // Ensure month and day are two digits
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const TabletimeFormat = (dateTimeString) => {
  const date = new Date(dateTimeString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  
  // Ensure the hours are always two digits
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const convertDate = (originalDate) => {
  // const date = new Date(originalDate);
  // const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  // return formattedDate;
  const d = originalDate.split('T')[0]
  const [year, month, day] = d.split('-');
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};
export const convertDateYYYYMMDD = (originalDate) => {
  const date = new Date(originalDate);

  // Extract month, day, and year
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
  const year = date.getFullYear();

  // Format as YYYYMMDD
  const formattedDate = `${year}${month}${day}`;

  return formattedDate;
};
///this is for submitting date
export const convertDateFormat1 = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const extractAgeYearOnly = (ageString) => {
  if (ageString && ageString != '') {
    const ageArray = ageString.split(' ');
    return parseInt(ageArray[0], 10);
  }
};


export const formatTime24hr = (dateString) => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  
  return formattedTime;
};
export const getTimeStringToDate = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const date = new Date();
  return date.setHours(hours, minutes, seconds, 0);
}

export const getDDDMMDD = (dateString) => {
  const date = new Date(dateString);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = days[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear().toString().slice(2);
  return `${dayOfWeek} ${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
}
export const getDDMM = (dateString) => {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-indexed
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
}
export const getTime12hrsData = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  const timezoneOffset = 5.5 * 60;
  const totalMinutes = hours * 60 + minutes + timezoneOffset;
  hours = Math.floor(totalMinutes / 60) % 24;
  minutes = totalMinutes % 60;
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  return formattedTime;
}