import { Routes, Route, useNavigate } from "react-router-dom";
import { AuthHeader } from "../../component/Navigation/authheader";
import { NoPageFound } from "../../component/NoPageFound/Nopagefound";
import { Bottom } from "../../component/Navigation/bottom";
import Otpverify from "./Two-Fa/Otpverify";
import LoginNew from "./login/LoginNew";
import Register from "./RegisterProvider/Register";
import IdentityVerification from "./RegisterProvider/IdentityVerification";
import IdMeHandler from "./IdMeHandler/IdMeHandler";
import About from "../../component/About/About";
import OtpScreen from "./OtpScreen";
import ResetPassword from "./ForgotPassword/ResetPassword";
import StaffMemberLogin from "./StaffMemberLogin/StaffMemberLogin";
import PaymentHandler from "./PaymentHandler/PaymentHandler";
import NewResetPassword from "../User-panel/Manage-Staff/NewResetPassword";

function Auth() {
  return (
    <div className="App" style={{background:'#F2F6F4'}}>
      <AuthHeader />
      <div className="main_content_area" style={{minHeight: `${window.innerHeight-80}px`, width:'80%'}}>
      {/* <div className="main_content_area" style={{width:'80%',minHeight:'100vh'}}> */}
        <Routes>
          <Route path="/" element={<LoginNew />} />
          <Route path="/login" element={<LoginNew />} />
          <Route path="/register" element={<Register />} />
          <Route path="/IdVerification" element={<IdentityVerification />} />
          <Route path="/resetpassword" element={<ResetPassword/>}/>
          <Route path="/otp" element={<Otpverify />} />
          <Route path="/staffMember" element={<StaffMemberLogin/>}/>
          <Route path="/idmehandler" element={<IdMeHandler />} />
          <Route path="/paymenthandler" element={<PaymentHandler/>}/>
          <Route path="/about" element={<About />} />
          <Route path="/updatepassword" element={<NewResetPassword show={true} />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </div>
      <Bottom />
    </div>
  );
}

export default Auth;
