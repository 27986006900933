import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import Select from "react-select";
import { selectStyle } from '../../../../theme/theme'

const CompleteAnalysis = (
  {
    show,
    onHide,
    analysisData,
    patientData,
    staffList
  }: any) => {
  const [btnActive, setIsBtnActive] = useState(false);
  const [staffId, setStaffId] = useState()
  const handleHide = () => {
    onHide();
  };
  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setStaffId(selectedOption.value);
    }
    // setAssessmentError("");
  };

  const handleSave = async () => {

    setIsBtnActive(false);
    onHide()
  }
  const staffMembers = staffList?.map((option: any) => ({
    value: option.uuid,
    label: option.firstName + ' ' + option.lastName,
  }));


  return (
    <div>
      <Modal
        className="assignModal assign-assessment-pop-model"
        show={show}
        animation={false}
        onHide={handleHide}
      >

        <div className="header-popup modal-header">
          <button onClick={handleHide} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
        </div>
        <Modal.Body className="body-popup">
          <div className="modal-body-inner">

            <h2 className='graphHeading'>Completed Analysis</h2>
            <br />
            <p className='mb-0'>Patient</p>
            <div className="custom-form-notEditable">
              {`${patientData?.firstName} ${patientData?.lastName}`}
            </div>

            <p className='mb-0'>30-Day Cycle End Date</p>
            <div className="custom-form-notEditable">
              {`${analysisData?.cycleEndDate}`}
            </div>

            <p className='mb-0'>Measurement</p>
            <div className="custom-form-notEditable">
              {`${analysisData?.analysis}`}
            </div>

            <div className="select-input-wrap">
              <p className="">Staff Member</p>
              <Select isDisabled={true}
                name="chooseAssessment"
                value={staffMembers?.find(
                  (option: any) => option.value == analysisData?.staffUid
                )}
                onChange={handleSelectChange}
                options={staffMembers}
                styles={selectStyle}
                isSearchable
              />
            </div>
            <div>
              <Form.Group controlId="message">
                <Form.Label className='mt-3'>Analysis Notes</Form.Label>
                <Form.Control disabled
                  as="textarea" value={analysisData?.analysisNotes}
                  name="message"
                  className="form-control custom-form-input-text-area"
                  rows={6}
                />
              </Form.Group>
            </div>



          </div>
          <Button
            className="primary-btn"
            onClick={handleSave}
            disabled={btnActive}
          >
            Submit
          </Button>

        </Modal.Body>
        {/* <Modal.Body className="body-popup">
        <div className="modal-body-inner">
         
          <h2 className='graphHeading'>Complete Analysis</h2>
          <br />


        </div>

        <div className="row">
          <div className="d-flex flex-row mt-5">
            <Button
              className="primary-btn"
              onClick={handleSave}
              disabled={btnActive}
            >
               Submit
            </Button>
          </div>
        </div>
      </Modal.Body> */}
      </Modal>
    </div>
  )
}


export default CompleteAnalysis