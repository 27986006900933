import React, { useState, useEffect } from "react";
import {
  btn_primary,
  btn_secondary,
  secondary,
  selectStyleSearch,
} from "../../../../theme/theme";
import alert from "../../../../assets/image/alert.png";
import alert_Icon from "../../../../assets/image/alert_Icon.png";

import { useNavigate } from "react-router-dom";

import PatientsTable from "../../../../theme/Table/Table";
import "primeicons/primeicons.css";
import NewAssessment from "../../../../theme/CreateAssessment/assignNewAssessment";
import { convertDate, convertDateFormat, currentDate } from "../../../utils/dateHelper";
import AppServices from "../../../../services/appServices";
import { APIEndpoint } from "../../../../config/apiendpoint";
import { TailSpin } from "react-loader-spinner";
import Select from 'react-select';
import axios from "axios";




function Dashboard() {

  const _appServices = new AppServices();
  const navigate = useNavigate();


  const [compAssData, setCompAssData] = useState<any[]>([])
  const [incompAssData, setIncompCompAssData] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pateintLoading, setPatientLoading] = useState(false);
  const [assessmentLoading, setAssessmentLoading] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await _appServices.getData(
          `${APIEndpoint.search}${searchQuery}`
        );
        setSearchResults(resp?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery.trim() !== "") {
      fetchData();
    } else {
      setSearchResults([]);
    }
   
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      setPatientLoading(true);
      try {
        const resp = await _appServices.getData(`${APIEndpoint.recentPatientAnalysis}`);
        if (resp?.status === 200 && resp?.success == true) {
          setCompAssData(resp.data)
          setPatientLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setPatientLoading(false);
      }
      try {
        const resp = await _appServices.getData(`${APIEndpoint.patientAlerts}`);
        console.log(resp)
        setAssessmentLoading(true)
        if (resp?.status === 200 && resp?.success == true) {
          setIncompCompAssData(resp.data)
          setAssessmentLoading(false)
        }
      } catch (error) {
        setAssessmentLoading(false)
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // const fetchData = async (page = 1, size = 10) => {
    //   setAssessmentLoading(true);
    //   try {
    //     const resp = await _appServices.getData(
    //       `${APIEndpoint.missingAssessments}?page=${page}&size=${size}`
    //     );
    //     if (resp?.status === 200 && resp?.success == true) {
    //       setIncompCompAssData(resp?.data);
    //       setAssessmentLoading(false);
    //     }
    //   } catch (error) {
    //     setAssessmentLoading(false);
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchData();
  }, []);

  const [show, setShow] = useState(false);


  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(false);
  };

  const handleEnroll = () => {
    navigate("/user-panel/enroll");
  };


  const assignAssessment = () => {
    setShow(true);
  };

  const handleInputChange = (value: any, { action }: any) => {
    if (action === "input-change") {
      setSearchQuery(value);
    }
  };

  function formatSearchResults(results: any[]) {
    return results.map((result: { firstName: any; uuid: any, dob: any, lastName: any }) => ({
      // label: result.firstName,
      label: `${result.lastName}, ${result.firstName} DOB: ${convertDate(result.dob)}`,
      value: result.uuid,
    }));
  }

  const onSelectionPatient = (patientsData: any) => {
    let selectedPatient: any = searchResults.find((item: any) => item.uuid == patientsData.value)
    setSelectedPatient(selectedPatient)
    // navigate('/user-panel/patientProfile/' + patientsData.value);
    navigate(
      `/user-panel/patientProfile/${selectedPatient.uuid}`,
      { state: selectedPatient }
    )
  };


  return (
    <div className="container1 top-margin-container">
      <div className="mb-5">
        <div className="row mt-3 mb-5">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-left p-0">
            <div>
              <h3 className="h3 thick-heading mb-4">Dashboard</h3>
              <p className="heading assessques dashColor m-0" style={{ color: '#9AA5B5' }}>
                Active Patients as of:
              </p>
              <h5 className="h5 thick-heading mb-4" style={{ fontSize: '24px' }}>{currentDate()}</h5>
            </div>
            <div className="d-flex flex-row col-10 pl-0">
              <div className="icon-container">
                <i className="pi pi-search" />
              </div>
              <Select
                className='custom-form-input-date mr-10'
                onChange={(selectedOption: any) => {
                  onSelectionPatient(selectedOption)
                }}
                onInputChange={handleInputChange}
                options={formatSearchResults(searchResults)}
                placeholder="Enter Name or DOB"
                styles={selectStyleSearch}
                isLoading={loading}
              />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0">
            <div className="d-flex flex-column text-right mr-2">
              <div className="w-100">
                <button
                  className="float-right m-3 primary-btn"
                  onClick={handleEnroll}
                >
                  + Enroll a New Patient
                </button>
              </div>
              {/* this can be made a component */}
              <div className="box">
                <div className="box-header">
                  <img
                    src={alert}
                    style={{ height: "33px", width: "33px" }}
                    alt=""
                  />
                  <p className="big-p thick-heading mt-1 ml-2">
                    Patient Alerts
                  </p>
                </div>
                {assessmentLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}> <TailSpin
                  visible={true}
                  height="80"
                  width="80"
                  color="#03A84E"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                /></div> :
                  <div className='inner-box'>
                    {incompAssData.length > 0 ?
                      <table className='table alert-table'>
                        <tbody>
                          {incompAssData.map((data: any, index) => (
                            <tr key={index} >
                              <td><p className='graphText thin-heading'>{convertDateFormat(data?.notifiedAt)}</p></td>


                              <td>
                                <div
                                  onClick={() => {
                                    _appServices.logData({
                                      Severity: 'Info',
                                      Activity: `User viewed ${data.firstName} profile.`,
                                      ActivityStatus: 'Success',
                                      ActivityResponse: 'Page View',
                                      Operation: 'User Activity',
                                    })
                                    navigate(
                                      '/user-panel/patientProfile/' + `${data.uuid}`,
                                      { state: data }
                                    );
                                  }}
                                >

                                  <p className='graphText thin-heading textColor capitalized cur-pointer'>{`${data?.firstName} ${data?.lastName} ${data?.middleName}`} </p>
                                  {/* <p className="heading graphText">DOB: {convertDate(item.dob)}</p> */}
                                </div>
                                {/*
                               */}
                              </td>
                              <td><p className='graphText thin-heading'>{data.alertDanger ? <img width={24} height={24} src={alert_Icon} /> : null} {data?.alertMessage}</p></td>
                              <td><p className='graphText thin-heading'>{data?.dueDate}</p></td>
                            </tr>))}

                        </tbody>
                      </table>
                      : (
                        <div className='inner-content'> <p className='graphText thin-heading'>No Data Found</p></div>
                      )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <h5 className="h5 thick-heading mb-4 text-left" style={{ fontSize: '24px' }}>Patients Pending Analysis</h5>
        {/* {compAssData.length> 0 ? ( */}
        <PatientsTable
          data={compAssData}
          isLoading={pateintLoading}
        />
        {/* ) : (<div><h6>.....Loading</h6></div>)} */}
        <p
          className="assessques thick-heading text-left mt-4"
          style={{ paddingTop: 10 }}
        >
          <a style={{ cursor: 'pointer' }} onClick={() => {
            navigate("/user-panel/viewallpatients");
          }} >View all Patients</a>
        </p>
      </div>

      <NewAssessment show={show} onHide={handleClose} onClick={handleShow} editMode={false} />
    </div>
  );
}

export default Dashboard;
