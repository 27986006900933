
import React, { useState } from 'react';
import editProvider from '../../../../assets/image/editProvider.png';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Col, Form } from 'react-bootstrap';
import { btn_primary } from '../../../../theme/theme';
import { useLocation, useNavigate } from "react-router-dom";
import AppServices from '../../../../services/appServices';
import { APIEndpoint } from '../../../../config/apiendpoint';
import CustomInput from '../../../component/CustomInput/CustomInput';
import { isValidPassword } from '../../../utils/validationHelper';
import Swal from 'sweetalert2';

const initialState = {
  otp: '',
  password: '',
  confirmPassword: '',
};

const initialStateErrors = {
  otpError: '',
  passwordError: '',
  confirmPasswordError: '',
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email || '';
  const _appServices = new AppServices()
  const [data, setdata] = useState(initialState);
  const [requestSubmitForgotPassword, setRequestSubmitForgotPassword] = useState(false);
  const [requestForgotPassword, setRequestForgotPassword] = useState(false);


  const [errors, seterrors] = React.useState(initialStateErrors)


  const _validateForm = () => {
    const validatePassword = isValidPassword(data.password, true);
    const errors: any = {};
    if (data.otp === '')
      errors.otpError = 'Verification Code is required.';
    // if (data.password === '')
    //   errors.passwordError = 'Password is required.';
    if (!validatePassword.isValid)
      errors.passwordError = validatePassword.message;
    if (data.confirmPassword === '')
      errors.confirmPasswordError = 'Confirm Password is required.';
    if (data.password !== data.confirmPassword)
      errors.confirmPasswordError = 'Confirm Password and Password did not matched.';

    return errors;
  };

  const onSubmit = () => {
    const errors = _validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }

    let postjson = {
      "email": email,
      "password": data.password,
      "confirmPassword": data.confirmPassword,
      "otp": data.otp,
      userRoleId: "2"
    }
    setRequestSubmitForgotPassword(true)
    _appServices.postData(APIEndpoint.confirmForgetPassword, postjson).then(async (res: any) => {
      if (res.status == 200 && res.success) {
        setRequestSubmitForgotPassword(false)
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.message || '',
        });
        navigate('/');
        _appServices.logData({
          Severity: 'Info',
          Activity: ` User clicked on Submit button from Reset Password Screen`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        });
      } else {
        setRequestSubmitForgotPassword(false)
        let errMessage = _appServices.getErrorMessage(res)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
        });
      }
    }, err => {
      setRequestSubmitForgotPassword(false)
      let errMessage = _appServices.getErrorMessage(err)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errMessage,
      });
    });
  };

  const _onChangeTextChange = (text: React.ChangeEvent<HTMLInputElement>, type: string) => {
    switch (type) {
      case 'OTP':
        setdata({
          ...data,
          otp: text.target.value,
        });
        seterrors({
          ...errors,
          otpError: '',
        });
        break;

      case 'PASSWORD':
        setdata({
          ...data,
          password: text.target.value,
        });
        seterrors({
          ...errors,
          passwordError: '',
        });
        break;

      case 'CONFIRM_PASSWORD':
        setdata({
          ...data,
          confirmPassword: text.target.value,
        });
        seterrors({
          ...errors,
          confirmPasswordError: '',
        });
        break;

      default:
        break;
    }
  }

  //   const[otpSent,setOtpSent]= useState(false)
  //   const [email,setEmail]= useState('')

  const handleForgotPassword = (values: any, setSubmitting: any, resetForm: any) => {
    console.log("values", values)
    //   setOtpSent(true)
    //   _appServices.postData(`${APIEndpoint.forgotPassword}`, values).then(res => {
    //     if (res.status == 200 && res.success) {
    //       resetForm();
    //       setOtpSent(true)
    //       setEmail(values.email)
    //     } else {
    //      window.alert(res.message)
    //     }
    //   }, err => {
    //     window.alert(err.message)
    //     // console.log(err.message)
    //   });
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {

  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {

  };

  const handleConfirmForgotPassword = () => {
    let postjson = {
      email: email,
      password: data.password,
      otp: data.otp
    }
    console.log(postjson)
    //   _appServices.postData(`${APIEndpoint.confirmForgetPassword}`, postjson).then(res => {
    //     if (res.status == 200 && res.success) {
    //       navigate('/login')
    //       resetForm();
    //     } else {
    //      window.alert(res.message)
    //     }
    //   }, err => {
    //     window.alert(err.message)
    //     // console.log(err.message)
    //   });
  }


  return (
    <div className='container top-margin-container'>
      <div className='row w-100 d-flex justify-content-center '>
        <div className='col-12 col-md-6 provider-login mt-4'>
          <div className='login-box'>
            <div className='text-left mt-2 d-flex flex-row align-items-center mb-5'>
              <img src={editProvider} style={{ height: '34px', width: '34px' }} alt='' />
              <h5 className='h5 headings ml-4'>Change Your Password</h5>

            </div>
            <p className='heading assessques mt-2 mb-4 text-left'>Create New Password </p>
            <p className='text-left assessques'>Must contain at least 10 characters, a symbol, a number, upper and lower case letters.</p>
            {/* <div className='text-left ml-5 d-flex flex-column'> */}
            <div className="">
              <CustomInput
                // placeholder='Verification Code'
                type='text'
                value={data.otp}
                onChange={(event) => _onChangeTextChange(event, 'OTP')}
                errorText={errors.otpError}
                label='Verification Code'
              />
              <CustomInput
                // placeholder='Password'
                type='password'
                value={data.password}
                onChange={(event) => _onChangeTextChange(event, 'PASSWORD')}
                errorText={errors.passwordError}
                icon={true}
                label='Password'
              />
              <CustomInput
                // placeholder='Confirm Password'
                type='password'
                value={data.confirmPassword}
                onChange={(event) => _onChangeTextChange(event, 'CONFIRM_PASSWORD')}
                errorText={errors.confirmPasswordError}
                icon={true}
                label='Confirm Password'
              />
            </div>
            <div className='login-btn-container'>
              <button onClick={onSubmit} className="btn btn-lg mt-4 mb-4 primary-btn">Submit</button>
            </div>
            {/* <Formik
          initialValues={resetPasswordValue}
          validationSchema={yup.object().shape({
            otp: yup.string().required('otp is required').matches(/^\d{6}$/, 'OTP must be a 6-digit number'),
            password: yup.string().required('Password is required'),
            reEnteredPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), ''], 'Passwords must match'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleConfirmForgotPassword(values,setSubmitting,resetForm)
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Col} md={6} controlId="validationFormik01">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  className='custom-form-input'
                  //placeholder="Password"
                  type="text"
                  name="otp"
                  value={values.otp || ''}
                  onChange={handleChange}
                  isInvalid={!!errors.otp}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.otp}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6} controlId="validationFormik02">
                <Form.Label>Enter Password</Form.Label>
                <Form.Control
                  className='custom-form-input'
                  //placeholder="Password"
                  type="password"
                  name="password"
                  value={values.password || ''}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6} controlId="validationFormik03">
                <Form.Label>Re-Enter New Password</Form.Label>
                <Form.Control
                  className='custom-form-input'
                  //placeholder="Password"
                  type="password"
                  name="reEnteredPassword"
                  value={values.reEnteredPassword || ''}
                  onChange={handleChange}
                  isInvalid={!!errors.reEnteredPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reEnteredPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type='submit' className='btn btn-lg mt-4 mb-4' style={btn_primary}>Submit</Button>
            </Form>
          )}
        </Formik> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>


  )
}




