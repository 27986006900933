import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import PatientHeader from '../../../component/PatientHeader/PatientHeader';
import AppServices from '../../../../services/appServices';
import BloodPressureGraph from '../../../component/graphs/BloodPressureGraph';
import RestingHeartRateGraph from '../../../component/graphs/RestingHeartRateGraph';
import ThirtyDayAnalysis from '../../../component/graphs/ThirtyDayAnalysis';
import DeviceTraining from '../../../component/graphs/DeviceTraining';
import ReportArchives from '../../../component/graphs/ReportArchives';
import ManageMonitoring from '../../../component/graphs/ManageMonitoring';
import './styles.css';
import NewTraining from '../../../../theme/AddTraining/addTraining';
import AddMonitoring from '../../../../theme/AddMonitoring/addMonitoring';
import { APIEndpoint } from '../../../../config/apiendpoint';
import BloodGlucoseGraph from '../../../component/graphs/BloodGlucoseGraph';
import { Blocks, TailSpin } from 'react-loader-spinner';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function PatientProfile() {
  const location = useLocation();
  const { state } = location;
  const [monitoringAssigned, setMonitoringAssigned] = useState(false);
  const [bloodPressureData, setBloodPressureData] = useState<any>([]);
  const [bloodGlucoseData, setBloodGlucoseData] = useState<any>([]);
  const [heartRateData, setHeartRateData] = useState<any>([]);
  const [patientData, setPatientData] = useState<any>(state);
  const [isShowTrainingPopup, setIsShowTrainingPopup] = useState(false);
  const [isShowMonitoringPopup, setIsShowMonitoringPopup] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [measurementTypeList, setMeasurementTypeList] = useState();
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [getTraining, setGetTraining] = useState(false);
  const [updateReportList, setUpdateReport] = useState(false);
  const [availablemonitoring, setAvailableMonitoring] = useState([]);
  const _appServices = new AppServices();

  useEffect(() => {
    get30DayAnalysisData();
    getAllStaffList();
    getMeasurementTypeList();
    checkMonitoring();
  }, []);

  const onGraphMeasurementData = async () => {
    const patId = patientData?.uuid;
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getPatientMeasurement}?patientUid=${patId}`);
      if (resp.status === 200 && resp.success === true) {
        let data = resp.data;
        setHeartRateData(data?.heartRate || []);
        setBloodPressureData(data?.bloodPressure || []);
        setBloodGlucoseData(data?.bloodGlucose || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkMonitoring = async () => {
    const patId = patientData?.uuid;
    setLoading(true); // Start loading
    try {
      const resp = await _appServices.getData(`${APIEndpoint.Monitoring}?patientUid=${patId}`);
      if (resp.status === 200 && resp.success === true) {
        const monitoringData = resp.data;
        // console.log(resp.data, "monitoring data ");
        const monitoring = monitoringData.find((item:any) => item.measurement); 
        setAvailableMonitoring(resp.data);
        setMonitoringAssigned(!!monitoring); 
        onGraphMeasurementData();
      }
    } catch (error) {
      console.error("Error checking blood glucose monitoring:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const getTrainingList = () => {
    setGetTraining(!getTraining);
  };

  const updateReport = () => {
    setUpdateReport(!updateReportList);
  };

  const getMeasurementTypeList = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.getMeasurement}`);
      if (resp.status === 200 && resp.success === true) {
        let data = resp.data;
        setMeasurementTypeList(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllStaffList = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.staff}?type=all`);
      if (resp.status === 200 && resp.success === true) {
        setStaffList(resp.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get30DayAnalysisData = async () => {
    const patId = patientData?.uuid;
    try {
      const resp = await _appServices.getData(`${APIEndpoint.logAnalysis}?patientUid=${patId}`);
      if (resp.status === 200 && resp.success === true) {
        let data = resp.data;
        // Further processing if needed
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseModal = () => {
    console.log('Modal closed');
  };

  const addMonitoring = () => {
    setIsShowTrainingPopup(true);
  };

  const handleClose = () => {
    setIsShowTrainingPopup(false);
  };

  const handleshowTrainingPopup = () => {
    setIsShowMonitoringPopup(true);
  };

  const handleCloseTrainingPopup = () => {
    setIsShowMonitoringPopup(false);
  };

  return (
    <div className='outContainer'>
      <PatientHeader
        patientData={patientData}
        onCloseModal={handleCloseModal}
      />
      <div className="mb-5 profile_page_container">
        <div className="row mt-3 mb-5">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pr-5">
            {loading ? ( 
               <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '50px'}}>
              <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#03A84E"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
                </div>
            ) : availablemonitoring.length ? (
              <>
                {availablemonitoring.map((item:any) => {           
                  return item.measurement === 1 ? (
                    <BloodPressureGraph data={bloodPressureData} usePdf={false} isMonitoringAssigned={monitoringAssigned} />
                  ) : item.measurement === 2 ? (
                    <BloodGlucoseGraph 
                      data={bloodGlucoseData} 
                      isMonitoringAssigned={monitoringAssigned}
                    />
                  ) : null;
                })}
              </>
            ) : (
              <p className='d-flex justify-content-center align-items-center mt-5'>
                No monitoring has been assigned yet.
              </p>
            )}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-0">
            <ThirtyDayAnalysis patientData={patientData} staffList={staffList} updateLog={updateReport} />
            <ManageMonitoring patientData={patientData} handleshowTrainingPopup={handleshowTrainingPopup} monitoringSuccess={checkMonitoring}/>
            <DeviceTraining patientData={patientData} addMonitoring={addMonitoring} setGetTraining={getTraining} />
            <ReportArchives patientData={patientData} updateReport={updateReportList} />
          </div>
        </div>
      </div>
      <AddMonitoring show={isShowMonitoringPopup} onHide={handleCloseTrainingPopup} editMode={false} />
      <NewTraining 
        patientData={patientData} 
        show={isShowTrainingPopup} 
        onHide={handleClose} 
        editMode={false} 
        measurementTypeList={measurementTypeList} 
        staffList={staffList} 
        getTrainingList={getTrainingList} 
      />
    </div>
  );
}
