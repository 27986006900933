import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Select from 'react-select';
import { TailSpin } from "react-loader-spinner";
import alert from "../../../assets/image/alert.png";
import alert_Icon from '../../../assets/image/alert_Icon.png';
import { convertDate, convertDateFormat, extractAgeYearOnly } from "../../utils/dateHelper";
import AppServices from "../../../services/appServices";
import { APIEndpoint } from "../../../config/apiendpoint";
import { selectStyleSearch } from "../../../../src/theme/theme";
import SendMessageModal from "../SendMessageModal/SendMessageModal";
import { getGender } from "../../utils/getRelation";

interface Props {
    patientData: any;
    onCloseModal: () => void;
    sendMessageSuccess?: () => void;
    isViewAll?: any
}

export default function PatientHeader({
    patientData,
    onCloseModal,
    sendMessageSuccess,
    isViewAll
}: Props) {
    const navigate = useNavigate();
    const params = useParams();
    const _appServices = new AppServices();

    const [compAssData, setCompAssData] = useState<any[]>([]);
    const [incompAssData, setIncompAssData] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState<any>(null);
    const [patient, setPatient] = useState<any>(null);
    const [patientInfo, setPatientInfo] = useState<any>({})
    const [assessmentLoading, setAssessmentLoading] = useState(true);


    useEffect(() => {
        // console.log(patientData, "patientInfo1")
        const fetchData = async () => {
            setLoading(true);
            try {

                if (searchQuery.trim() !== "") {
                    const searchResp = await _appServices.getData(`${APIEndpoint.search}${searchQuery}`);
                    setSearchResults(searchResp?.data || []);
                } else {
                    setSearchResults([]);
                }


                // const compAssResp = await _appServices.getData(`${APIEndpoint.recentAssessments}`);
                // if (compAssResp?.status === 200 && compAssResp?.success) {
                //     setCompAssData(compAssResp.data);
                // }


                // const incompAssResp = await _appServices.getData(`${APIEndpoint.missingAssessments}?page=1&size=10`);
                // if (incompAssResp?.status === 200 && incompAssResp?.success) {
                //     setIncompAssData(incompAssResp.data);
                // }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
            try {
                const resp = await _appServices.getData(`${APIEndpoint.patientAlerts}/?patientUid=${patientData?.uuid}`);
                setAssessmentLoading(true)
                if (resp?.status === 200 && resp?.success == true) {
                    setIncompAssData(resp.data)
                    setAssessmentLoading(false)
                }
            } catch (error) {
                setAssessmentLoading(false)
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [searchQuery]);

    const handleClose = () => {
        setShow(false);
        onCloseModal();
    };

    const handleShow = (item: any) => {
        setShow(true);
        setPatient(item);
    };

    const viewAllpatientMessage = () => {
        navigate(`/user-panel/viewAllMessages`, { state: patientData });
    };

    const handleInputChange = (value: any, { action }: any) => {
        if (action === "input-change") {
            setSearchQuery(value);
        }
    };

    const formatSearchResults = (results: any[]) => {
        return results.map((result: { firstName: any; uuid: any; dob: any; lastName: any }) => ({
            label: `${result.lastName}, ${result.firstName} DOB: ${convertDate(result.dob)}`,
            value: result.uuid,
        }));
    };

    const onSelectionPatient = (patientsData: any) => {
        const selected = searchResults.find((item: any) => item.uuid === patientsData.value);
        setSelectedPatient(selected);
        // navigate(`/user-panel/patientProfile/${patientsData.value}`);
        navigate('/user-panel/loader')
        if (isViewAll) {
            setTimeout(() => {
                navigate(`/user-panel/viewAllMessages`, { state: selected });
            }, 100)
        } else {
            setTimeout(() => {
                navigate(
                    `/user-panel/patientProfile/${selected.uuid}`,
                    { state: selected }
                )
            }, 100)
        }
        onChangePatientProfile(selected)
    };



    useEffect(() => {
        if (patientData) {
            // console.log(patientData, "PATIENTDATA")
            setPatientInfo({
                PatientName: `${patientData?.firstName} ${patientData?.lastName}`,
                age: (patientData && patientData.age) ? String(patientData.age).split(',')['0'] : 'N/A',
                DOB: convertDate(patientData?.dob),
                Gender: getGender(patientData?.gender),
                Email: (patientData?.email),
                MobileNumber: maskMobileNumber(patientData?.phoneNumber)
            })
            setPatient(patientData);
        }
    }, [])
    const onSendMessageSuccess = () => {
        if (sendMessageSuccess) {
            sendMessageSuccess()
        }
    }

    const onChangePatientProfile = (p: any) => {
        setPatientInfo({
            PatientName: `${p?.firstName} ${p?.lastName}`,
            age: (p && p.age) ? String(p.age).split(',')['0'] : 'N/A',
            DOB: convertDate(p?.dob),
            Gender: getGender(p?.gender),
            Email: (p?.email),
            MobileNumber: maskMobileNumber(p?.phoneNumber)
        })
        setPatient(p);
    }


const maskMobileNumber=(number:any)=> {
  
  const cleanNumber = number.toString().replace(/\D/g, '');

  if (cleanNumber.length === 10) {
    return `(${cleanNumber.substring(0, 3)}) ${cleanNumber.substring(3, 6)}-${cleanNumber.substring(6)}`;
  } else {
    return '';
  }
}

    return (
        <div className="top-margin-container1">
            <div className="mb-5 patient-container">
                <div className="row mt-3 mb-5">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-12 text-left p-0">
                        <div className="mt-5">
                            <br /><br />
                            <h3 className="h3 mt-4 thick-heading1 mb-4">Patient Profile</h3>
                            <div className="mt-4 p-2">
                                <div className="d-flex flex-column text-left">
                                    <h5 className="h5 headingPatientProfile capitalized">{patientInfo?.PatientName}</h5>
                                    <span className="d-flex flex-column flex-md-row patientInfo">
                                        <p className="headingPatientProfile garphText mr-2 d-flex mb-0">
                                            <span className="assessques headingPatientProfile">DOB: </span>
                                            <span className="graphText thin-heading">&nbsp; {patientInfo?.DOB}</span>
                                        </p>
                                        <p className="headingPatientProfile garphText mr-2 d-flex mb-0">
                                            <span className="assessques thick-heading">Age: </span>
                                            <span className="graphText thin-heading">&nbsp; {patientInfo?.age}</span>
                                        </p>
                                        <p className="headingPatientProfile garphText mr-2 d-flex mb-0">
                                            <span className="assessques thick-heading">Gender: </span>
                                            <span className="graphText thin-heading">&nbsp; {patientInfo?.Gender}</span>
                                        </p>
                                    </span>
                                    <span className="d-flex flex-column flex-md-row patientInfo">
                                        <p className="headingPatientProfile garphText mr-2 d-flex mb-0">
                                            <span className="assessques thick-heading">Email: </span>
                                            <span className="graphText1 thin-heading">&nbsp; {patientInfo?.Email || 'N/A'}</span>
                                        </p>
                                        <p className="headingPatientProfile garphText mr-2 d-flex mb-0 ">
                                            <span className="assessques1 thick-heading">Mobile number: </span>
                                            <span className="graphText1 thin-heading mobile-number">&nbsp; {patientInfo?.MobileNumber || 'N/A'}</span>
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-4 col-12 p-0">
                        <div className="new-div-content mt-5">
                            <br /><br />
                            <Button
                                className="secondary-btn mt-5"
                                type="submit"
                                disabled={loading}
                                onClick={() => handleShow(patientData)}
                            >
                                Send a Message
                            </Button>
                            {(patientData && !isViewAll) ? <p onClick={viewAllpatientMessage} style={{ color: '#03A84E', marginTop: '10px', cursor: 'pointer' }}>+ View All Messages</p> : null}
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                        <div className="d-flex flex-column mr-2">
                            <div className="w-100 text-right">
                                <p className="patientsearch">Search for Patient</p>
                                <div className="d-flex flex-row col-10 pl-0">
                                    <div className="icon-container">
                                        <i className="pi pi-search" />
                                    </div>
                                    <Select
                                        className='custom-form-input-date mr-10'
                                        onChange={onSelectionPatient}
                                        onInputChange={handleInputChange}
                                        options={formatSearchResults(searchResults)}
                                        placeholder="Enter Name or DOB"
                                        styles={selectStyleSearch}
                                        isLoading={loading}
                                    />
                                </div>
                            </div>
                            <div className="box mt-4">
                                <div className="box-header">
                                    <img
                                        src={alert}
                                        style={{ height: "33px", width: "33px" }}
                                        alt="Alert"
                                    />
                                    <p className="big-p thick-heading mt-1 ml-2">Patient Alerts</p>
                                </div>
                                {assessmentLoading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <TailSpin
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#03A84E"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                        />
                                    </div>
                                ) : (
                                    <div className='inner-box'>
                                        {incompAssData.length > 0 ? (
                                            <table className='table alert-table'>
                                                <tbody>
                                                    {incompAssData.map((data: any, index: number) => (
                                                        <tr key={index}>
                                                            <td><p className='graphText thin-heading'>{convertDateFormat(data?.notifiedAt)}</p></td>
                                                            <td><p className='graphText thin-heading  capitalized'>{data.alertType}</p></td>
                                                            <td><p className='graphText thin-heading'>{data.alertDanger ? <img width={24} height={24} src={alert_Icon} /> : null} {data.alertMessage}</p></td>
                                                            <td><p className='graphText thin-heading'>{data?.dueDate}</p></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className='inner-content'><p className='graphText thin-heading'>No Data Found</p></div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show && <SendMessageModal
                showModal={show}
                handleClose={handleClose}
                data={patient}
                handleSuccess={onSendMessageSuccess}

            />}
        </div>
    );
}
