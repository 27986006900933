import { NoPageFound } from "../app/component/NoPageFound/Nopagefound";
import EnrollPatient from "../app/container/User-panel/CreatePatient/EnrollPatient";
import InvitationSent from "../app/container/User-panel/Invitation/InvitationSent";
import EditPatient from "../app/container/Authorization/CreateProvider/EditProvider";
import Dashboard from "../app/container/User-panel/Dashboard/Dashboard";
import ViewAllPatients from "../app/container/User-panel/All-Patients/ViewAllPatients";
import AssignedAssessments from "../app/container/User-panel/Assigned-Assesments/AssignedAssessments";
import ManagePatientsInfo from "../app/container/User-panel/Manage-Patients/ManagePatientsInfo";
import PatientProfile from "../app/container/User-panel/Patient-Profile/PatientProfile";
import ViewMessages from "../app/container/User-panel/All-Messages/ViewMessages";
import AssessmentTemplate from "../app/container/User-panel/Assessment-Template/AssessmentTemplate";
import EditAssessment from "../app/container/User-panel/Assessment-Template/EditAssessment";
import { Navigate } from "react-router-dom";
import About from "../app/component/About/About";
import KnowYourAssessments from "../app/container/User-panel/Know-Assessments/KnowYourAssessments";
import UserGuides from "../app/container/User-panel/Communities/userGuides";
import UserGuideDetails from "../app/container/User-panel/Communities/userGuideDetails";
import ManageStaffMember from "../app/container/User-panel/Manage-Staff/ManageStaffMember";
import EditStaffMember from "../app/container/User-panel/Manage-Staff/EditStaffMember";
import PageLoader from "../app/container/User-panel/Loader";
import NewResetPassword from "../app/container/User-panel/Manage-Staff/NewResetPassword";

export const appRouter = [
    {
        path:'/',
        element: <Navigate to='/user-panel/dashboard' />,
        errorElement: <NoPageFound/>
    },
    {
        path:'/dashboard',
        element: <Dashboard/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/invitation',
        element: <InvitationSent/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/enroll',
        element: <EnrollPatient/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/edit',
        element: <EditPatient/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/userGuides',
        element: <UserGuides/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/userGuideDetails',
        element: <UserGuideDetails/>,
        errorElement: <NoPageFound/>
    },
    // {
    //     path:'/provider-mfa',
    //     element: <ProviderMFA/>,
    //     errorElement: <NoPageFound/>
    // },
    // {
    //     path:'/verification',
    //     element: <VerificationStatus/>,
    //     errorElement: <NoPageFound/>
    // },
    {
        path:'/viewallpatients',
        element: <ViewAllPatients/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/viewallAssessments',
        element: <AssignedAssessments/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/managePatients',
        element: <ManagePatientsInfo/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/patientProfile/:id',
        element: <PatientProfile/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/viewAllMessages',
        element: <ViewMessages/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/assessmentTemplate',
        element: <AssessmentTemplate/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/editAssessment',
        element: <EditAssessment/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/about',
        element: <About/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/knowYourAssessments',
        element: <KnowYourAssessments/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/manageStaffMember',
        element: <ManageStaffMember/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/editMemberProfile',
        element: <EditStaffMember/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'/loader',
        element: <PageLoader/>,
        errorElement: <NoPageFound/>
    },
    {
        path:'*',
        element: <NoPageFound/>,
        // errorElement: <NoPageFound/>
    },
]