import {
    CartesianGrid,
    Dot,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis, YAxis
} from 'recharts';
import { convertDateFormat, getDDDMMDD, getDDMM, getTime12hrsData } from '../../utils/dateHelper';
import "../graphs/styles.css";
import alert_Icon from "../../../assets/image/alert_Icon.png";

const BloodGlucoseGraphPdf = ({ data }) => {

    const CustomDot = ({ cx, cy, fill, payload }) => {
        let dotColor = fill;
        dotColor = payload.isAbnormalGlucoseReading ? 'red' : '#E2B71F';
        return (
            <circle
                cx={cx}
                cy={cy}
                r={4}
                fill={dotColor}
                stroke="none"
                // Ensure no hover effect
                style={{ pointerEvents: 'none' }}
            />
        );
    };
    const calculateBarchartWidth = (numberOfItem = 7) => { if (numberOfItem > 6) return numberOfItem * 75; return '100%'; };


    const formattedData = (data)?.map(entry => ({
        date: getDDMM(entry.date),
        range: entry.range,
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading
    }));


    const tableData = (data)?.map(entry => ({
        dateTime: `${convertDateFormat(entry.date)} ${getTime12hrsData(entry.date)}`,
        device: entry?.device,
        range: `${entry.range} ${entry.unit}`,
        isAbnormalGlucoseReading: entry?.isAbnormalGlucoseReading
    }));

    return (
        <div className="graph-container">
            {(data && data.length) ? <div style={{ display: 'flex', marginLeft: '-15pt' }}>
                <div style={{ width: '65px' }}>
                    <ResponsiveContainer width={'100%'} height={275}>
                        <LineChart data={formattedData}>

                            <YAxis
                                type="number"
                                dataKey='range'
                                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                tick={{ fill: '#000', fontSize: 12 }}
                                padding={{ right: 20 }}
                                tickLine={false}
                                style={{ fontFamily: 'Open Sans, sans-serif' }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div style={{ overflowX: 'auto', width: 'calc(100% - 70px)' }}>
                    <ResponsiveContainer height={300}>
                        <LineChart data={formattedData}>
                            <XAxis
                                dataKey="date"
                                angle={90} // Rotate labels to vertical
                                dx={5} // Center the labels along the axis
                                dy={10}
                                minTickGap={-50}
                                textAnchor="middle" // Center the text on the axis line
                                tick={{ fill: '#000', fontSize: 12, transform: 'rotate(90deg)' }} // Ensure text is rotated
                                tickLine={false}
                                axisLine={{ stroke: '#D9D9D9', strokeWidth: 1 }}
                                padding={{
                                    right: 20,
                                }}
                                style={{ fontFamily: 'Open Sans, sans-serif' }}
                            />
                            <Tooltip />
                            <Legend
                                verticalAlign="top"
                                align="right"
                                iconType="circle"
                                iconSize={26}
                                wrapperStyle={{ paddingLeft: 20, }}
                                formatter={(value, entry) => (
                                    <span style={{ color: '#000', fontSize: 14 }}>
                                        {value}
                                    </span>
                                )}
                            />
                            <Line
                                type="monotone"
                                dataKey="range"
                                stroke="#E2B71F"
                                name="mg/dL"
                                strokeWidth={3}
                                dot={(props) => <CustomDot {...props} />}

                            />
                            <ReferenceLine y={140} stroke="black" strokeDasharray="3 3" />
                            <ReferenceLine y={70} stroke="black" strokeDasharray="3 3" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div> :
                <p className='d-flex justify-content-center align-items-center' style={{ minHeight: 300 }}>No Chart Data Found</p>
            }

            {<div style={{ marginLeft: '-10pt' }}>
                <div className="col-12 mt-2 ">
                    <p className="font-bold">30-Days of readings </p>
                </div>
                <div className="col-12 mb-3">
                    {<div>
                        {(tableData?.length > 0) && <table className=" custom-adli-table table">
                            <thead>
                                <tr className="table th text-left custom-adli-table">
                                    <th className="h custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Date</th>
                                    <th className="custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Device</th>
                                    <th className="custom-adli-table" style={{ fontSize: 16, fontWeight: 'bold' }}>Range</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.map((entry, index) => (
                                    <tr key={index} >
                                        <td className="h custom-adli-table" style={{ fontSize: 16 }}>{entry?.dateTime}</td>
                                        <td className="custom-adli-table" style={{ fontSize: 16 }}> {entry?.device}</td>
                                        <td className="custom-adli-table" style={{ fontSize: 16 }}>{entry?.isAbnormalGlucoseReading ? <img width={24} height={24} src={alert_Icon} /> : <span style={{ marginLeft: '22pt' }} >{' '}</span>} {entry.range}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        {tableData?.length == 0 && <p>
                            No Data Found
                        </p>}
                    </div>}
                </div>

            </div>}
        </div>
    );
}

export default BloodGlucoseGraphPdf;
