import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Blocks, TailSpin } from 'react-loader-spinner';
import { convertDateFormat } from '../../app/utils/dateHelper';

interface TableProps {
  data: Array<any>;
  isLoading: boolean;
}

const MessageTable: React.FC<TableProps> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const [expandedMessage, setExpandedMessage] = useState<number | null>(null);

  const decodeHTMLEntities = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const HTMLPartToTextPart = (HTMLPart: string) => (
    HTMLPart
      .replace(/\n/ig, '')
      .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
      .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
      .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
      .replace(/<\/\s*(?:p|div)>/ig, '\n')
      .replace(/<br[^>]*\/?>/ig, '\n')
      .replace(/<[^>]*>/ig, '')
      .replace(/&nbsp;/ig, ' ')
      .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
      .replaceAll('&amp;', '&')
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&quot;', '"')
      .replaceAll('&#39;', "'")
  );

  const getMessageContent = (message: any, index: any) => {
    const sanitizedMessage = HTMLPartToTextPart(message);
    const expanded = expandedMessage === index;
    const maxLength = 100;

    if (sanitizedMessage.length > maxLength) {
      if (expanded) {
        return sanitizedMessage;
      } else {
        return sanitizedMessage.slice(0, maxLength);
      }
    } else {
      return sanitizedMessage;
    }
  };

  const toggleExpand = (index: number) => {
    setExpandedMessage((prev) => (prev === index ? null : index));
  };

  return (
    <div className="table-responsive" style={{ maxHeight: 400 }}>
      <table className=" custom-adli-table table">
        <thead>
          <tr className="table th text-left">
            <th className="h col-2">
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 'bold' }}>Date Sent</span>
            </th>
            <th className='col-2'>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 'bold' }}>From</span>
            </th>
            <th className='col-8'>
              <span className="mr-3" style={{ fontSize: 14, fontWeight: 'bold' }}>Subject Line</span>
            </th>
          </tr>
        </thead>
        <tbody className='text-left'>
          {
            isLoading ? (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  <div className='d-flex align-items-center justify-content-center' style={{ height: '100px' }}>
                    <div>
                      <TailSpin
                        height="60"
                        width="60"
                        color="#3889B1"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              data?.length > 0 ? data.map((item, index) => (
                <tr
                  className='align-item-center justify-content-center wordwrap'
                  key={index}
                >
                  <td className="h p-1 col-2">
                    <p className="heading graphText m-0 p-2">{convertDateFormat(item.createdAt)}</p>
                  </td>
                  <td className='p-1 col-2'>
                    <p className="heading graphText m-0 p-2 capitalized">
                      {item.recipient.firstName} {item.recipient.middleName} {item.recipient.lastName}
                    </p>
                  </td>
                  <td className='p-1 col-8 ' style={{ whiteSpace: 'wrap' }}>
                    <div className="assessques d-flex m-0 p-2">
                      {HTMLPartToTextPart(item.message).length > 100 ? (
                        <>
                          {expandedMessage === index ? (
                            <>
                              <div className='flex'>
                                <div className="assessques d-flex m-0 innercontent-fixed-font-size">{getMessageContent(item.message, index)}</div>
                                <div
                                  className='textColor'
                                  onClick={() => toggleExpand(index)}
                                  style={{ cursor: 'pointer', position: 'absolute', bottom: 5, right: 10, fontWeight: 'bold' }}
                                >
                                  /\
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="assessques m-0 innercontent-fixed-font-size">{getMessageContent(item.message, index)}</div>
                              <div
                                className='textColor ml-auto'
                                onClick={() => toggleExpand(index)}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              >
                                ...more
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <span className="assessques d-flex m-0 innercontent-fixed-font-size">{getMessageContent(item.message, index)}</span>
                      )}
                    </div>
                  </td>
                </tr>
              ))
                :
                <tr>
                  <td colSpan={3} className='text-center h'><span className='p-4 thick-heading'>No data</span></td>
                </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default MessageTable;