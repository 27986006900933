import React, { useEffect, useState } from "react";

import "primeicons/primeicons.css";
import PatientsTable from "../../../../theme/Table/Table";
import AppServices from "../../../../services/appServices";
import { APIEndpoint } from "../../../../config/apiendpoint";
import ViewAllPatientTable from "../../../../theme/Table/ViewAllPatientTable";
import Select from "react-select";
import { selectStyleSearch } from "../../../../theme/theme";
import { useNavigate, useParams } from "react-router-dom";
import { convertDate, convertDateFormat } from "../../../utils/dateHelper";

export default function ViewAllPatients() {
  const _appServices = new AppServices();
  const navigate = useNavigate();

  const [patientList, setpatientList] = useState([]);

  const [pateintLoading, setPatientLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [ selectedPatient, setSelectedPatient ] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await _appServices.getData(
          `${APIEndpoint.search}${searchQuery}`
        );

        setSearchResults(resp?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery.trim() !== "") {
      fetchData();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);
  

  useEffect(() => {
    const fetchData = async () => {
      setPatientLoading(true);
      try {
        const resp = await _appServices.getData(`${APIEndpoint.allPatient}`);
        if (resp.status === 200 && resp.success) {
          setpatientList(resp?.data);
          setPatientLoading(false);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        setPatientLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (value: any, { action }: any) => {
    setSelectedOption(null)
    if (action === "input-change") {
      setSearchQuery(value);
      setSelectedOption(null)
    }
  };

  // function formatSearchResults(results: any[]) {
  //   return results.map((result: { firstName: any; uuid: any }) => ({
  //     label: result.firstName,
  //     value: result.uuid,
  //   }));
  // }

  const onSelectionPatient = (patientsData: any) => {
    let selectedPatient: any = searchResults.find((item: any) => item.uuid == patientsData.value)
    setSelectedPatient(selectedPatient)
    setSelectedOption(patientsData);
    // navigate('/user-panel/patientProfile/' + patientsData?.value); 
    navigate(
      `/user-panel/patientProfile/${selectedPatient.uuid}`,
      { state: selectedPatient }
    )
  };


  function formatSearchResults(results: any[]) {
    return results.map((result: { firstName: any; uuid: any, dob: any, lastName: any }) => ({
      // label: result.firstName,
      label: `${result?.lastName}, ${result?.firstName} DOB: ${convertDate(result?.dob)}`,
      value: result?.uuid,
    }));
  }

  return (
    <div className="container top-margin-container">
      <div className="d-md-flex flex-row  align-items-center mb-4">
        <div className=" col-12 col-md-8 pl-0 flex-column text-left">
          <h5 className="h5 heading">View All Patients</h5>
        </div>

        <div className="d-flex col-12 col-md-4 flex-column text-left pl-0 pr-0">
          <label className="thick-heading assessques dashColor">
            Quick Search
          </label>
          <div className="d-flex flex-row">
            <div className="icon-container">
              <i className="pi pi-search" />
            </div>
            <Select
              className="custom-form-input-date"
              onChange={(selectedOption: any) => {
                onSelectionPatient(selectedOption)
              }}
              onInputChange={handleInputChange}
              options={formatSearchResults(searchResults)}
              placeholder="Enter Name or DOB"
              styles={selectStyleSearch}
              isLoading={loading}
              value={selectedOption}
            />
          </div>
        </div>
      </div>

      <ViewAllPatientTable
        data={patientList}
        isLoading={pateintLoading}
      />
    </div>
  );
}
