import React, { useEffect, createContext } from 'react'
import { useState } from 'react';
import { number } from 'yup';
// import { envConfig } from '../config/env_config';


export const AppUserProvider = ({ children }) => { 
    const [sessionDetails, setSessionDetails] = useState(null);
    const [userRoleId, setUserRoleId] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [authToken, setAuthToken] = useState(null);
    // eslint-disable-next-line
    const [iDmeVerification,setIDmeVerification] = useState(false);
    // eslint-disable-next-line
    const [isRegisterCompleted,setIsRegisterCompleted] = useState(false)
    // eslint-disable-next-line
    const [isNPIMatched,setIsNPIMatched] = useState(false)

    // const [userType, setUserType] = useState();
    // const [userTypeId, setUserTypeId] = useState();
    // const [isFirstTimeUser, setIsFirstTimeUser] = useState();
    const [loggedInUserDetails, setloggedInUserDetails] = useState(null);
    //  const [isCompleteWalkthroug, setIsCompleteWalkthrough] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [genderInfo, setGenderInfo] = useState([]);
    const [relationshipInfo, setRelationshipInfo] = useState([]);
    const [frequencyInfo, setFrequencyInfo] = useState([]);
    const [ countryCode, setCountryCode] = useState([]);
    // localStorage.clear()
    
    useEffect(() => {
        async function recoverSession() {
            //  localStorage.removeItem('sessionObject')
            const oldSessionDetails =  localStorage.getItem('sessionObject');
            const sessionObj = oldSessionDetails ? JSON.parse(oldSessionDetails) : null;
            console.log('sessionObj',sessionObj)
            if (oldSessionDetails && sessionObj) {
                setSessionDetails(sessionObj)
                setIsLoggedIn(sessionObj?.isLoggedIn)
                setAuthToken(sessionObj?.authToken);
                setIDmeVerification(sessionObj?.iDmeVerification)
                setIsNPIMatched(sessionObj?.isNPIMatched)
                setIsRegisterCompleted(sessionObj?.isRegisterCompleted)
                // setIsFirstTimeUser(sessionObj?.isFirstTimeUser);
                // setUserType(sessionObj?.userType);
                // setUserTypeId(sessionObj?.userTypeId);
                setUserRoleId(sessionObj?.userTypeId)
            } 
        }
        recoverSession();
    }, []);

    useEffect(() => {
        async function setSession() {
            if (sessionDetails) {
                localStorage.setItem('sessionObject', JSON.stringify(sessionDetails));
                setSessionDetails(sessionDetails)
                setIsLoggedIn(sessionDetails?.isLoggedIn)
                setAuthToken(sessionDetails?.authToken);
                setIDmeVerification(sessionDetails?.iDmeVerification)
                setIsNPIMatched(sessionDetails?.isNPIMatched)
                setIsRegisterCompleted(sessionDetails?.isRegisterCompleted)
                // setIsFirstTimeUser(sessionDetails?.isFirstTimeUser);
                // setUserType(sessionDetails?.userType);
                // setUserTypeId(sessionDetails?.userTypeId);
                setUserRoleId(sessionDetails?.userTypeId)
            } 
        }
        setSession();
    }, [sessionDetails])

    const defaultValue = {
        sessionDetails,
        setSessionDetails,
        isLoggedIn,
        setIsLoggedIn,
        authToken,
        setAuthToken,
        // userType,
        // userTypeId,
        // setUserType,
        // isFirstTimeUser,
        // setIsFirstTimeUser,
        loggedInUserDetails,
        setloggedInUserDetails,
        // isCompleteWalkthroug,
        // setIsCompleteWalkthrough
        userDetails,
        setUserDetails,
        genderInfo,
        setGenderInfo,
        relationshipInfo,
        setRelationshipInfo,
        frequencyInfo,
        setFrequencyInfo,
        countryCode,
        setCountryCode,
        userRoleId,
        setUserRoleId
    }
    return (
        <UserContext.Provider value={defaultValue}>
            {children}
        </UserContext.Provider>
    )
}

export const UserContext = createContext(
    {
        sessionDetails:null, 
        setSessionDetails: (data) => { },
        isLoggedIn: false,
        setIsLoggedIn: (data) => { },
        authToken: null,
        setAuthToken: () => { },
        // userType: null,
        // setUserType: () => { },
        // setUserTypeId: () => { },
        // isFirstTimeUser: null,
        // setIsFirstTimeUser: () => { },
        loggedInUserDetails:null,
        setloggedInUserDetails: () => { },
        // isCompleteWalkthroug:null,
        // setIsCompleteWalkthrough: () => { },        
        userDetails: null,
        setUserDetails: (data) => { },
        genderInfo: [],
        setGenderInfo: (data) => { },
        relationshipInfo: [],
        setRelationshipInfo: (data) => { },
        frequencyInfo: [],
        setFrequencyInfo:(data) => {},
        countryCode: [],
        setCountryCode: (data) => {},
        userRoleId: null,
        setUserRoleId: (data) => { },
    }
);
export default AppUserProvider;