import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { IoMdCloseCircle } from "react-icons/io";
import CustomInput from '../../../component/CustomInput/CustomInput';
import { isValidEmail } from '../../../utils/validationHelper';
import { useNavigate } from 'react-router-dom';
// import AppServices from '../../../services/appServices';
// import { APIEndpoint } from '../../../config/apiendpoint_config';
// import PrimaryButton from '../../../component/PrimaryButton';
import Swal from 'sweetalert2';
import { top_header_color } from '../../../../theme/theme';
import { APIEndpoint } from '../../../../config/apiendpoint';
import AppServices from '../../../../services/appServices';
import { notifySuccess } from '../../../../theme/Toaster/quickToasts';

interface ForgotPasswordModalProps {
  show: boolean;
  onHide: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ show, onHide }) => {

  const _appServices = new AppServices()
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError,setEmailError]= useState('');
  const [loading, setLoading] = useState(false);


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError('')
  };

  const validateForm = () => {
    const errors:any = {};
    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      errors.emailError = 'Invalid Email Format.';
    }
    if (!email) {
      errors.emailError = 'Email is required.';
    }
    return errors;
  };

  const handleClose =()=>{
    setEmailError('');
    setEmail('');
    onHide()
  }

  const handleSubmit = async() => {
    const errors = validateForm();
    if (Object.keys(errors).length) {
      setEmailError(errors.emailError);
      return;
    }

    if (email) {
      let postjson = {
        "email": email,
        userRoleId: "2"
      }
      setLoading(true)
      _appServices.postData(APIEndpoint.forgotPassword, postjson).then(async res => {
        if (res.status == 200 && res.success) {
          let _res = res.data[0];
          // console.log('res', res)
          setLoading(false);
          notifySuccess(res?.message);
          navigate('/resetpassword', {state:{ res: _res,email: email }})
          setEmail('');
          handleClose(); 
        } else {
          setLoading(false)
          let errMessage = _appServices.getErrorMessage(res)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      },async (err) => {
        setLoading(false) 
        let errMessage = _appServices.getErrorMessage(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errMessage,
      });
       
      });
    }
  };

  return (
    // <Modal show={show} onHide={onHide}>
   <div className='forget-modal'>
   
          <button onClick={handleClose} className='close-button' > <IoMdCloseCircle color={top_header_color} size={40} /></button>
 

       <div style={{padding:'50px'}}>

       <div>
            <h2>Forgot Password</h2>
            <p style={{fontSize:'22px',lineHeight:'1.4', marginBottom:'15px'}}> We will send you the password reset link in your email</p>
        </div>
        <CustomInput type='email'
         placeholder='User@email.com' 
         value={email}
         onChange={handleEmailChange}
         errorText={emailError}
         />
        {/* <PrimaryButton handleSubmit={handleSubmit} text='Send' loading={loading}/> */}
         <button className='btn btn-lg mt-4 mb-4 primary-btn' style={{width:'100%'}} onClick={handleSubmit} >Send</button>
       </div>
    
   </div>
    // </Modal>
  );
};

export default ForgotPasswordModal;
